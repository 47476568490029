import ApiResponse = models.server.api.international.ApiResponse
import Payload = models.server.api.graphQuery.homepage.Root
import SubjectArea = models.server.api.international.subjectArea.SubjectArea

export default function () {
  const getIntCourse = async (id: string | number) => {
    const { data, error } = await useFetch<ApiResponse>(
      `/api/international/course`,
      {
        method: 'POST',
        body: {
          id: 'is-search-courses',
          params: {
            from: 0,
            size: 1,
            filters: [
              {
                field: 'id',
                value: id,
                last: true
              }
            ]
          }
        }
      }
    )

    let courseResult

    if (data.value) {
      if (data.value?.hits?.hits.length < 1) {
        throw createError({
          statusCode: 404
        })
      } else {
        courseResult = data.value.hits.hits[0]._source
      }
    }

    return { courseResult, error }
  }

  const getPathway = async (id: string | number) => {
    const { data, error } = await useFetch<ApiResponse>(
      `/api/international/package`,
      {
        method: 'POST',
        body: {
          id: 'is-search-courses',
          params: {
            from: 0,
            size: 1,
            filters: [
              {
                field: 'id',
                value: id,
                last: true
              }
            ]
          }
        }
      }
    )

    let pathwayResult

    if (data.value) {
      if (data.value?.hits?.hits.length < 1) {
        throw createError({
          statusCode: 404
        })
      } else {
        pathwayResult = data.value.hits.hits[0]._source
      }
    }

    return { pathwayResult, error }
  }

  const getAdditionalCourse = async (ids: string[]) => {
    const additionalResult = []
    for (const id of ids) {
      const data = await $fetch<ApiResponse>(`/api/international/course`, {
        method: 'POST',
        body: {
          id: 'is-search-courses',
          params: {
            from: 0,
            size: 1,
            filters: [
              {
                field: 'id',
                value: id,
                last: true
              }
            ]
          }
        }
      })

      if (data) {
        if (data?.hits?.hits.length > 0) {
          additionalResult.push(data.hits.hits[0]._source)
        }
      }
    }

    return additionalResult
  }

  const getIntHomepageContent = async () => {
    const sitecoreGraphQuery = useSitecoreGraphQueryStore()
    const data = await sitecoreGraphQuery.fetch<Payload>(
      '6D854C30-6F4F-4DF1-8EAF-3BA881565430'
    )

    return data || {}
  }

  const getSubjectArea = async (slug: string) => {
    const sitecoreGraphQuery = useSitecoreGraphQueryStore()

    const data = await sitecoreGraphQuery.fetch<SubjectArea>(
      'F7CA2D67-D31D-48C7-AA79-A63653669000',
      {
        // clean the parameter to replace '&' with 'and', parentheses and apostrophe with empty string to avoid errors in subjectArea name mismatch TISMS API vs siteCore API
        queryParameter1: slug.replace(/[&()']/g, (match) =>
          match === '&' ? 'and' : ''
        )
      }
    )

    return data || {}
  }

  return {
    getIntCourse,
    getPathway,
    getAdditionalCourse,
    getIntHomepageContent,
    getSubjectArea
  }
}
